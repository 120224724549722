var exports = {};

exports = function (hljs) {
  var BUILT_INS = {
    "builtin-name": "each in with if else unless bindattr action collection debugger log outlet template unbound view yield"
  };
  return {
    aliases: ["hbs", "html.hbs", "html.handlebars"],
    case_insensitive: true,
    subLanguage: "xml",
    contains: [hljs.COMMENT("{{!(--)?", "(--)?}}"), {
      className: "template-tag",
      begin: /\{\{[#\/]/,
      end: /\}\}/,
      contains: [{
        className: "name",
        begin: /[a-zA-Z\.-]+/,
        keywords: BUILT_INS,
        starts: {
          endsWithParent: true,
          relevance: 0,
          contains: [hljs.QUOTE_STRING_MODE]
        }
      }]
    }, {
      className: "template-variable",
      begin: /\{\{/,
      end: /\}\}/,
      keywords: BUILT_INS
    }]
  };
};

export default exports;